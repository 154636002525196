.scrolltop {
    z-index: 10;
    position: fixed;
    bottom: 40px;
    right: 40px;
    .scroll-none {
      opacity: 0;
      visibility: hidden;
    }
    a {
      background-color: var(--ocean-blue);
      padding: 1rem;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.4s ease-in-out;
      z-index: 1;
      svg {
        color: white;
        font-size: 1.3rem;
      }
    }
  }