.promo-container {
    background-color: var(--ocean-blue);
    color: white;
    margin: 2rem;
    border-radius: 2rem;
    .container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      text-align: center;
      .content {
        .title-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          p {
            line-height: 1.5rem;
            letter-spacing: 0.1rem;
          }
        }
  
        .subscribe-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          background-color: white;
          padding: 1rem 0;
          border-radius: 0.5rem;
          input {
            width: 90%;
            border: none;
            padding: 0.5rem 0;
            text-align: center;
            font-size: 1.1rem;
            &:focus {
              outline: none;
            }
          }
          button {
            width: 90%;
            border: none;
            background-color: var(--ocean-blue);
            color: white;
            font-size: 1.2rem;
            font-weight: bold;
            padding: 0.5rem 0;
            border-radius: 0.5rem;
          }
        }
      }
      .promo-image {
        img {
          max-inline-size: 100%;
          block-size: auto;
        }
      }
    }
    @media screen and (min-device-width: 1200px) {
      .container {
        flex-direction: row-reverse;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 2rem;
          padding: 0 5rem;
          .subscribe-container {
            flex-direction: row;
            padding: 0.5rem 1rem;
            input {
              width: 70%;
            }
            button {
              width: 30%;
              height: 100%;
              padding: 1rem;
            }
          }
        }
       /* .promo-image {
          img {
            //   max-inline-size: none;
          }
        } */
      }
    }
  }