footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
    .brand-container {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .brand {
        display: flex;
        justify-content: center;
        gap: 0.3rem;
        span {
          font-size: 2rem;
          color: var(--dark-blue);
          font-weight: bolder;
          font-family: Verdana, Geneva, Tahoma, sans-serif;
        }
        .dot {
          color: var(--ocean-blue);
        }
      }
      .description {
        color: var(--dark-blue);
      }
      .social-links {
        display: flex;
        gap: 2rem;
        justify-content: center;
        li {
          background-color: var(--red);
          padding: 0.8rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2rem;
          transition: var(--transition);
          &:hover {
            background-color: white;
            svg {
              color: var(--red);
            }
          }
          svg {
            color: white;
            font-size: 1.3rem;
          }
        }
      }
    }
    .links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      .link {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .title {
          color: var(--dark-blue);
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          li {
            a {
              text-decoration: none;
              color: var(--light-blue);
            }
          }
        }
      }
    }
    @media only screen and (min-device-width: 768px) {
      flex-direction: row;
      gap: 5rem;
      .brand-container {
        width: 40%;
      }
    }
    @media screen and (min-device-width: 1200px) {
      .links {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }