.choose-us-container {
    .container {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      .title-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        h2 {
          color: var(--dark-blue);
        }
        p {
          color: var(--light-blue);
          letter-spacing: 0.1rem;
          line-height: 1.2rem;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .choose-us {
          display: grid;
          grid-template-columns: 1fr;
          gap: 2rem;
  
          .choose {
            background-color: var(--card-color2);
  
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 1rem;
            padding: 1rem;
            border-radius: 0.7rem;
  
            box-shadow: var(--fixed-shadow);
            transition: var(--transition);
            &:hover {
              box-shadow: var(--elevate-shadow);
            }
            h4 {
              color: var(--dark-blue);
            }
            p {
              color: var(--light-blue);
            }
          }
        }
        .delivery {
          display: flex;
          justify-content: center;
          img {
            max-inline-size: 100%;
            block-size: auto;
          }
        }
      }
    }
    @media only screen and (min-device-width: 768px) {
      .container {
        .choose-us {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    @media screen and (min-device-width: 1200px) {
      .container {
        .content {
          flex-direction: row-reverse;
          gap: 4rem;
          .choose-us {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }
    }
  }